
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TmSpinner',
  props: {
    size: {
      type: String,
      default: '20px',
    },
    color: {
      type: String,
      default: 'white',
    },
  },
})
