import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_highlight = _resolveComponent("highlight")!
  const _component_tm_chip = _resolveComponent("tm-chip")!

  return (_openBlock(), _createBlock(_component_tm_chip, {
    class: "tm-status",
    static: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_highlight, {
        text: _ctx.capitalizeFirstLetter(_ctx.name),
        search: _ctx.search
      }, null, 8, ["text", "search"])
    ]),
    _: 1
  }))
}