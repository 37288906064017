import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b9076f3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "scrollContent",
  class: "tm-scrollbar__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "scroll",
    class: _normalizeClass(["tm-scrollbar", {
      [_ctx.observedScrollbarQuasarClassName]: true,
      'tm-scrollbar--visible': _ctx.visibleScrollbar || _ctx.visible,
      'tm-scrollbar--scroll-x': _ctx.allowScrollX,
      'tm-scrollbar--visible-y': _ctx.visibleScrollbarY,
      'tm-scrollbar--scroll-y': _ctx.allowScrollY,
      'tm-scrollbar--offset': _ctx.showOffset,
      'tm-scrollbar--transparent-scrollbar': _ctx.transparentScrollbar,
      'tm-scrollbar--stable': _ctx.stable,
      'tm-scrollbar--dark': _ctx.dark,
      'tm-scrollbar--remove-width': _ctx.removeScrollbarWidth,
      'tm-scrollbar--hide-scrollbar': _ctx.hideScrollbar,
      'tm-scrollbar--content-bottom': _ctx.contentBottom,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 512)
  ], 2))
}