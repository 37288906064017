
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import EditorButton from '@/components/shared/editor/EditorButton.vue'
import type { EditorButtonType } from '@/definitions/shared/editor/types'

export default defineComponent({
  components: {
    EditorButton,
  },
  props: {
    item: {
      type: Object as PropType<EditorButtonType>,
    },
    disable: {
      type: Boolean,
    },
    isActive: {
      type: Boolean,
    },
    noteState: {
      type: Boolean,
    },
  },
})
