
import { defineComponent, ref, onMounted } from 'vue'
import { tmAutoSizePanelParentClass } from '@/definitions/shared/autoSize/data'

export default defineComponent({
  setup() {
    const root = ref()
    const inDropdown = ref()

    onMounted(() => {
      inDropdown.value = root.value.closest(`.${tmAutoSizePanelParentClass}`)
    })

    return {
      root,
      inDropdown,
    }
  },
})
