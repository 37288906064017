import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-priority", {
      [`tm-priority--${_ctx.size}`]: _ctx.size,
    }])
  }, [
    (_ctx.typeSelect)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_tm_icon, {
            "custom-size": _ctx.iconSize,
            color: _ctx.typeSelect.color,
            name: _ctx.typeSelect.icon
          }, null, 8, ["custom-size", "color", "name"]),
          _withDirectives(_createElementVNode("span", {
            class: _normalizeClass(["tm-priority--text", {'font-weight-semi-bold' : _ctx.semiBold}])
          }, _toDisplayString(_ctx.typeSelect.title), 3), [
            [_vShow, !_ctx.iconOnly]
          ]),
          (_ctx.showTooltip)
            ? (_openBlock(), _createBlock(_component_tm_tooltip, { key: 0 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.typeSelect.title), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: "tm-priority__unset",
          style: _normalizeStyle(`width: ${_ctx.iconSize}; height: ${_ctx.iconSize}`)
        }, null, 4))
  ], 2))
}