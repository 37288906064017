import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createBlock(_component_tm_field, {
    ref: "field",
    "model-value": _ctx.modelValue,
    "left-icon": "search",
    class: _normalizeClass(["tm-search", {
      'tm-search--light-border': _ctx.lightBorder,
    }]),
    clearable: "",
    size: _ctx.size,
    onClear: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', ''))),
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }, null, 8, ["model-value", "class", "size"]))
}