import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2034df67"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "field-editor__footer"
}
const _hoisted_2 = { class: "field-editor__btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_scrollbar = _resolveComponent("tm-scrollbar")!
  const _component_editor_agent_typing = _resolveComponent("editor-agent-typing")!
  const _component_message_editor_formatting = _resolveComponent("message-editor-formatting")!
  const _component_editor_buttons = _resolveComponent("editor-buttons")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_message_editor_schedule = _resolveComponent("message-editor-schedule")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field-editor", {
      'field-editor--note': _ctx.noteState,
      'field-editor--focused': _ctx.editorFocused,
      'field-editor--disabled': _ctx.disable,
      'field-editor--no-outline': !_ctx.outline,
      'field-editor--no-box-shadow': !_ctx.boxShadow,
      'field-editor--grow-y': _ctx.growY,
    }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["field-editor__textarea-wrap", {
        'field-editor__textarea-wrap--grow-y': _ctx.growY,
      }])
    }, [
      _createVNode(_component_tm_scrollbar, null, {
        default: _withCtx(() => [
          _createVNode(_component_tm_field, {
            "model-value": _ctx.modelValue,
            rows: _ctx.rows,
            class: "field-editor__textarea",
            placeholder: _ctx.placeholder,
            type: "textarea",
            autogrow: _ctx.autogrow,
            size: "small",
            autofocus: _ctx.autofocus,
            disable: _ctx.disable,
            "onUpdate:modelValue": _ctx.onInputChanged,
            onFocus: _ctx.handleFocus,
            onBlur: _ctx.handleBlur
          }, null, 8, ["model-value", "rows", "placeholder", "autogrow", "autofocus", "disable", "onUpdate:modelValue", "onFocus", "onBlur"])
        ]),
        _: 1
      }),
      _renderSlot(_ctx.$slots, "after-textarea", {}, undefined, true),
      (_ctx.showAgentTyping)
        ? (_openBlock(), _createBlock(_component_editor_agent_typing, {
            key: 0,
            class: "mb-2px"
          }))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "before-formatting", {}, undefined, true),
      (_ctx.showFormatting)
        ? (_openBlock(), _createBlock(_component_message_editor_formatting, {
            key: 1,
            "note-state": _ctx.noteState,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFormatting = false))
          }, null, 8, ["note-state"]))
        : _createCommentVNode("", true)
    ], 2),
    (!_ctx.scheduled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "btns", {}, () => [
              (_ctx.buttons?.length)
                ? (_openBlock(), _createBlock(_component_editor_buttons, {
                    key: 0,
                    "show-formatting": _ctx.showFormatting,
                    "onUpdate:show-formatting": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showFormatting) = $event)),
                    buttons: _ctx.buttons,
                    "note-state": _ctx.noteState,
                    disable: _ctx.disable,
                    onToggleNoteState: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:noteState', !_ctx.noteState))),
                    onShowSchedule: _cache[3] || (_cache[3] = ($event: any) => (_ctx.scheduled = true))
                  }, null, 8, ["show-formatting", "buttons", "note-state", "disable"]))
                : _createCommentVNode("", true)
            ], true)
          ]),
          (!_ctx.hideSendButton)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["field-editor__send pointer", {'pointer': _ctx.modelValue?.length}])
              }, [
                _renderSlot(_ctx.$slots, "send-button", _normalizeProps(_guardReactiveProps({ modelValue: _ctx.modelValue })), () => [
                  _createVNode(_component_tm_icon, {
                    name: "send",
                    size: "xxxLarge",
                    class: _normalizeClass(_ctx.computedBtnColor)
                  }, null, 8, ["class"])
                ], true)
              ], 2))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createBlock(_component_message_editor_schedule, {
          key: 1,
          onHideSchedule: _cache[4] || (_cache[4] = ($event: any) => (_ctx.scheduled = false))
        }))
  ], 2))
}