import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["tm-auto-size", { 'tm-auto-size--extra-offset': _ctx.extraOffsetForFocus }])
  }, [
    _createElementVNode("div", {
      ref: "listRef",
      class: _normalizeClass(["tm-auto-size__scroll", [
        _ctx.scrollClass,
        { 'tm-auto-size__scroll--scroll-x': _ctx.allowScrollX },
        { 'tm-auto-size__scroll--line-clamp': _ctx.linesCount > 1 },
      ]])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ], 2))
}