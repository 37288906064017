
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmAutoSizePanelItem from '@/components/shared/autoSize/panel/TmAutoSizePanelItem.vue'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import type { EditorBtnsEmitType, EditorButtonType } from '@/definitions/shared/editor/types'
import { useModals } from '@/compositions/modals'
import { tmAutoSizeIgnoreClass } from '@/definitions/shared/autoSize/data'
import EditorButtonTemplate from '@/components/shared/editor/EditorButtonTemplate.vue'

export default defineComponent({
  name: 'EditorButtons',
  components: {
    EditorButtonTemplate,
    TmAutoSizePanel,
    TmAutoSizePanelItem,
    TmButton,
    TmDropdownItem,
  },
  props: {
    message: {
      type: String,
    },
    showFormatting: {
      type: Boolean,
    },
    disable: {
      type: Boolean,
    },
    noteState: {
      type: Boolean,
    },
    buttons: {
      type: Array as PropType<EditorButtonType[]>,
    },
  },
  emits: ['toggle-note-state', 'update:show-formatting', 'show-schedule'] as EditorBtnsEmitType[],
  // EditorBtnsEmitType - unique emits list type
  setup(props, { emit }) {
    const { openModal, closeModal } = useModals()
    const emptyMessage = computed(() => !props.message)
    const visibleButtons = computed(() => props.buttons?.filter(item => !item.permanent && !item.showInDropdown))
    const permanentButtons = computed(() => props.buttons?.filter(item => item.permanent))
    const dropdownButtons = computed(() => props.buttons?.filter(item => item.showInDropdown))

    const btnClick = (item: EditorButtonType) => {
      item?.clickEvent && item.clickEvent()

      if (item?.emitName) {
        item.emitName === 'update:show-formatting'
          ? emit(item.emitName, !props.showFormatting)
          : emit(item.emitName)
      }
    }

    const isBtnActive = (item: EditorButtonType) => {
      switch (item.name) {
        case 'text': return props.showFormatting
        case 'note': return props.noteState
        default: return false
      }
    }

    return {
      openModal,
      closeModal,
      emptyMessage,
      tmAutoSizeIgnoreClass,
      visibleButtons,
      permanentButtons,
      dropdownButtons,
      btnClick,
      isBtnActive,
    }
  },
})
