import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_skeleton = _resolveComponent("q-skeleton")!

  return (_openBlock(), _createBlock(_component_q_skeleton, {
    class: _normalizeClass(["tm-skeleton", {
      'tm-skeleton--dark': _ctx.dark,
    }]),
    type: _ctx.type,
    tag: _ctx.tag,
    animation: _ctx.animation,
    width: _ctx.widthValue,
    height: _ctx.heightValue
  }, null, 8, ["class", "type", "tag", "animation", "width", "height"]))
}