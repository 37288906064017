
import { defineComponent, ref, onMounted, onBeforeUnmount, nextTick, computed, watch } from 'vue'
import { tmAutoSizeIgnoreClass, tmAutoSizeHiddenClass } from '@/definitions/shared/autoSize/data'

const __default__ = defineComponent({
  props: {
    hiddenItems: {
      type: Number,
      default: 0,
    },
    maxVisibleItems: {
      type: Number,
      default: 0,
    },
    minVisibleItems: {
      type: Number,
      default: 0,
    },
    allowScrollX: {
      type: Boolean,
    },
    scrollClass: {
      type: String,
    },
    minHiddenItems: {
      type: Number,
      default: 0,
    },
    linesCount: {
      type: Number,
      default: 1,
    },
    extraOffsetForFocus: {
      type: Boolean,
    },
  },
  emits: ['update:hiddenItems', 'update:visibleItems'],
  setup(props, context) {
    const root = ref<HTMLElement>()
    const listRef = ref<HTMLElement>()
    const children = ref()
    let resizeObserver: ResizeObserver
    const mutableHiddenItems = ref(0)
    const ignoreHiddenItems = ref(0)
    const hiddenItemsExist = computed(() => mutableHiddenItems.value > 0)
    const visibleItemsCount = computed(() => children.value?.length - mutableHiddenItems.value)

    const shouldHideOnInit = (): boolean => {
      if (props.maxVisibleItems) {
        return (children.value.length - props.maxVisibleItems - ignoreHiddenItems.value) > 0
      }
      return false
    }

    const initialItemsHiding = () => {
      if (shouldHideOnInit()) {
        // hide items if them more than maxVisibleItems
        // and ignoreHiddenItems because they will be visible anyway
        children.value.slice(0, children.value.length - props.maxVisibleItems + ignoreHiddenItems.value).forEach((e: HTMLElement) => {
          e.classList.add(tmAutoSizeHiddenClass)
        })
      }
    }

    const updateHiddenItems = () => {
      mutableHiddenItems.value = root.value!.querySelectorAll(`.${tmAutoSizeHiddenClass}`).length - ignoreHiddenItems.value
      context.emit('update:hiddenItems', mutableHiddenItems.value)
      context.emit('update:visibleItems', visibleItemsCount.value)
    }

    const cutVisibleItem = async () => {
      if (!listRef.value) { return }
      const extraOffset = props.extraOffsetForFocus ? 8 : 0
      const listWidth = listRef.value.scrollWidth + extraOffset
      const parentWidth = root.value!.offsetWidth

      const listHeight = listRef.value!.scrollHeight
      const parentHeight = root.value!.offsetHeight

      const conditionByWidth = listWidth > parentWidth || (hiddenItemsExist.value && mutableHiddenItems.value < props.minHiddenItems)
      const conditionByHeight = listHeight > parentHeight
      const condition = props.linesCount > 1 ? conditionByHeight : conditionByWidth

      if (condition) {
        const itemToHide = children.value.find((e: HTMLElement) => !e.classList.contains(tmAutoSizeHiddenClass))
        if (itemToHide) {
          if (visibleItemsCount.value <= props.minVisibleItems) {
            return
          }

          itemToHide.classList.add(tmAutoSizeHiddenClass)
          updateHiddenItems()

          await nextTick()
          await cutVisibleItem()
        }
      }
    }

    const updateVisibleChildren = async () => {
      children.value = [...listRef.value!.children].reverse()
      ignoreHiddenItems.value = children.value.filter((el: HTMLElement) => el.classList.contains(tmAutoSizeIgnoreClass)).length

      children.value.forEach((e: HTMLElement) => {
        e.classList.remove(tmAutoSizeHiddenClass)
      })

      initialItemsHiding()
      updateHiddenItems()

      await nextTick()
      await cutVisibleItem()
    }

    onMounted(() => {
      resizeObserver = new ResizeObserver(updateVisibleChildren)
      resizeObserver.observe(listRef.value!)
      resizeObserver.observe(root.value!)
    })

    onBeforeUnmount(() => {
      resizeObserver.disconnect()
      resizeObserver.disconnect()
    })

    watch(() => props.maxVisibleItems, updateVisibleChildren)

    return {
      root,
      listRef,
      ignoreHiddenItems,
      children,
      updateVisibleChildren,
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "2f1f201e": (_ctx.linesCount)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__