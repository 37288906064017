import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a703bb3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "root",
  class: "tm-auto-size-panel-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, _ctx.inDropdown ? 'dropdown-item' : 'default', {}, undefined, true)
  ], 512))
}