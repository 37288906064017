import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, normalizeClass as _normalizeClass, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e3973de"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "onMousemove"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps({
    ref: "listboxEl",
    class: ["tm-interactive-list", { 'tm-interactive-list--overflow': _ctx.overflow }]
  }, _ctx.listboxAttrs), [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.internalItems, (opt, i) => {
      return (_openBlock(), _createElementBlock("div", {
        id: opt.listUid,
        ref_for: true,
        ref: el => { _ctx.itemsELs[i] = el },
        key: opt.listUid,
        class: _normalizeClass({ 'tm-interactive-list--disabled': opt.disabled }),
        role: "option",
        tabindex: "-1",
        onMousemove: ($event: any) => (_ctx.onMousemove($event, i)),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
      }, [
        _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({ opt: _ctx.items[i], index: i, focused: _ctx.optionIndex === i })), undefined, true)
      ], 42, _hoisted_1))
    }), 128))
  ], 16))
}