import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "font-weight-semi-bold font-size-13"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createBlock(_component_tm_button, {
    class: "editor-button",
    "icon-only": !_ctx.item.showLabel,
    disabled: _ctx.disable || _ctx.item.disable,
    size: "medium",
    flat: "",
    color: _ctx.noteState ? 'transparent-note' : 'transparent',
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.tooltipState = false))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_tm_tooltip, {
        modelValue: _ctx.tooltipState,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tooltipState) = $event))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.item.text), 1)
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_tm_icon, {
        name: _ctx.item.icon,
        "custom-size": _ctx.item.iconCustomSize,
        size: "xLarge",
        left: _ctx.item.showLabel
      }, null, 8, ["name", "custom-size", "left"]),
      (_ctx.item.showLabel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.item.text), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["icon-only", "disabled", "color"]))
}