
import type { PropType } from 'vue'
import { computed, defineComponent, ref, watch } from 'vue'
import TmAutoSize from '@/components/shared/autoSize/TmAutoSize.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import { tmAutoSizeIgnoreClass, tmAutoSizePanelParentClass } from '@/definitions/shared/autoSize/data'
import type { SizeProp, ButtonSize, ButtonColor, IconSize } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmAutoSize,
    TmButton,
    TmDropdown,
  },
  props: {
    btnFlat: {
      type: Boolean,
    },
    iconSize: {
      type: String as SizeProp<IconSize>,
      default: 'medium',
    },
    btnSize: {
      type: String as SizeProp<ButtonSize>,
    },
    hideText: {
      type: Boolean,
    },
    btnColor: {
      type: String as PropType<ButtonColor>,
    },
    dropdownItemsHiddenClass: {
      type: String,
      default: 'ml-2',
    },
    extraOffsetForFocus: {
      type: Boolean,
    },
    showTooltip: {
      type: Boolean,
    },
  },
  emits: ['update:hidden-items-count'],
  setup(props, { emit, slots }) {
    const autoSize = ref()
    const hiddenItemsCount = ref(0)
    const visibleItemsCount = ref(0)
    const dropdownMenu = ref()
    const updateHiddenItems = () => {
      if (!dropdownMenu.value) {
        return
      }

      const children: HTMLElement[] = [...dropdownMenu.value.children].reverse()

      children.forEach((e: HTMLElement, i) => {
        if (i < hiddenItemsCount.value) {
          e.classList.remove('tm-auto-size-panel-hidden-item')
        } else {
          e.classList.add('tm-auto-size-panel-hidden-item')
        }
      })
    }

    watch(() => hiddenItemsCount.value, () => {
      updateHiddenItems()
      emit('update:hidden-items-count', hiddenItemsCount.value)
    })
    const dropdownItemsHiddenClassComputed = computed(() => (hiddenItemsCount.value > 0 && visibleItemsCount.value > 1) || (slots['dropdown-default'] && visibleItemsCount.value > 1))

    const updateVisibleChildren = () => autoSize.value.updateVisibleChildren()

    return {
      updateVisibleChildren,
      autoSize,
      visibleItemsCount,
      dropdownItemsHiddenClassComputed,
      hiddenItemsCount,
      tmAutoSizeIgnoreClass,
      tmAutoSizePanelParentClass,
      dropdownMenu,
      updateHiddenItems,
    }
  },
})
