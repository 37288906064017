
import { defineComponent, ref } from 'vue'
import type { PropType } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import type { EditorButtonType } from '@/definitions/shared/editor/types'

export default defineComponent({
  components: { TmTooltip, TmButton },
  props: {
    item: {
      type: Object as PropType<EditorButtonType>,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    noteState: {
      type: Boolean,
    },
  },
  setup() {
    const tooltipState = ref(false)

    return {
      tooltipState,
    }
  },
})
