import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-322d324b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field-editor-footer-upper-menu__buttons" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = {
  key: 0,
  class: "separator"
}
const _hoisted_4 = {
  key: 0,
  class: "dropdown-separator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_auto_size_panel_item = _resolveComponent("tm-auto-size-panel-item")!
  const _component_tm_auto_size_panel = _resolveComponent("tm-auto-size-panel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field-editor-footer-upper-menu", {
      'field-editor-footer-upper-menu--note' : _ctx.noteState
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_tm_auto_size_panel, {
        "btn-icon-only": "",
        "close-on-content-click": "",
        "btn-flat": "",
        color: "transparent",
        "btn-size": "medium"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (item, index) => {
            return (_openBlock(), _createBlock(_component_tm_auto_size_panel_item, { key: index }, {
              "dropdown-item": _withCtx(() => [
                _createVNode(_component_tm_button, {
                  flat: "",
                  "icon-only": "",
                  color: "transparent",
                  class: _normalizeClass(["dropdown-button", {
                'is-active': item.isActive || _ctx.activeButtons.includes(item.name),
              }]),
                  size: "medium",
                  onClick: ($event: any) => (item.clickFunction ? item.clickFunction() : _ctx.toggleButton(item.name))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_tm_icon, {
                      name: item.icon,
                      size: "xLarge"
                    }, null, 8, ["name"])
                  ]),
                  _: 2
                }, 1032, ["class", "onClick"]),
                (item.separatorAfter)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_tm_button, {
                    "icon-only": "",
                    flat: "",
                    color: "transparent",
                    class: _normalizeClass({
                'is-active': item.isActive || _ctx.activeButtons.includes(item.name)
              }),
                    size: "medium",
                    onClick: ($event: any) => (item.clickFunction ? item.clickFunction() : _ctx.toggleButton(item.name))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_tm_icon, {
                        name: item.icon,
                        size: "xLarge"
                      }, null, 8, ["name"]),
                      _createVNode(_component_tm_tooltip, {
                        delayed: "",
                        "delay-time": _ctx.tooltipDelayTime
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.tooltip), 1)
                        ]),
                        _: 2
                      }, 1032, ["delay-time"])
                    ]),
                    _: 2
                  }, 1032, ["class", "onClick"]),
                  (item.separatorAfter)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_tm_button, {
      "icon-only": "",
      flat: "",
      color: "transparent",
      class: "gt-sm",
      size: "medium",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_icon, {
          name: "close",
          size: "large"
        })
      ]),
      _: 1
    })
  ], 2))
}