
import type { PropType } from 'vue'
import { watch, ref, computed, defineComponent } from 'vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import EditorButtons from '@/components/shared/editor/EditorButtons.vue'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import MessageEditorFormatting from '@/components/shared/chat/messageEditor/MessageEditorFormatting.vue'
import MessageEditorSchedule from '@/components/shared/chat/messageEditor/MessageEditorSchedule.vue'
import EditorAgentTyping from '@/components/shared/editor/EditorAgentTyping.vue'

const __default__ = defineComponent({
  name: 'FieldEditor',
  components: {
    EditorAgentTyping,
    MessageEditorSchedule,
    MessageEditorFormatting,
    EditorButtons,
    TmScrollbar,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    buttons: {
      type: Array as PropType<EditorButtonType[]>,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Type your text message here',
    },
    noteState: {
      type: Boolean,
    },
    autofocus: {
      type: Boolean,
    },
    disable: {
      type: Boolean,
    },
    autogrow: { // высота textarea увеличивается по мере заполнения. Можно ограничить через prop.maxHeight
      type: Boolean,
    },
    maxHeight: {
      type: String,
      default: 'unset',
    },
    minHeight: {
      type: String,
      default: '0',
    },
    hideSendButton: {
      type: Boolean,
    },
    outline: {
      type: Boolean,
      default: true,
    },
    boxShadow: {
      type: Boolean,
      default: true,
    },
    growY: { // принимает высоту родителя
      type: Boolean,
    },
    rows: { // для полей с небольшой высотой :rows="1"
      type: Number,
      default: 3,
    },
    showAgentTyping: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'update:noteState', 'blur', 'focus', 'update:showFormatting'],
  setup(props, context) {
    const scheduled = ref(false)
    const editorFocused = ref(false)
    const showFormatting = ref(false)
    const onInputChanged = (value: string) => {
      context.emit('update:modelValue', value)
    }

    const handleBlur = () => {
      editorFocused.value = false
      context.emit('blur')
    }
    const handleFocus = () => {
      editorFocused.value = true
      context.emit('focus')
    }

    const computedBtnColor = computed(() => {
      if (props.noteState && props.modelValue) { return 'orange500--text' }
      if (props.noteState && !props.modelValue) { return 'orange300--text' }
      if (props.disable) { return 'blue200--text' }
      if (!props.modelValue) { return 'blue300--text' }
      return 'primary--text'
    })

    watch(showFormatting, () => {
      context.emit('update:showFormatting', showFormatting.value)
    })

    return {
      showFormatting,
      onInputChanged,
      computedBtnColor,
      editorFocused,
      handleBlur,
      handleFocus,
      scheduled,
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "4b5f42c1": (_ctx.minHeight),
  "1d542c53": (_ctx.maxHeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__