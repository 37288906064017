
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { utcZonesOptions } from '@/definitions/_general/_data/utc'

export default defineComponent({
  components: { TmButton },
  emits: ['hide-schedule'],
  setup() {
    const hour = ref('08')
    const minutes = ref('08')
    const format = ref('AM')
    const formatOptions = ['AM', 'PM']
    const date = ref(new Date('2022-04-19'))
    const timezone = ref(utcZonesOptions[16])

    return {
      hour,
      minutes,
      format,
      formatOptions,
      date,
      timezone,
    }
  },
})
