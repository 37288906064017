
import type { PropType, ComputedRef } from 'vue'
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import TmAutoSizePanelItem from '@/components/shared/autoSize/panel/TmAutoSizePanelItem.vue'
import { delayOptions } from '@/definitions/_general/_data/general'

export type MessageEditorFooterUpperMenuButton = {
  name: string;
  icon: string;
  tooltip: string;
  isActive?: ComputedRef<boolean>;
  clickFunction?: () => void;
  separatorAfter?: boolean;
}

export default defineComponent({
  components: {
    TmButton,
    TmTooltip,
    TmAutoSizePanel,
    TmAutoSizePanelItem,
  },
  props: {
    buttons: {
      type: Array as PropType<MessageEditorFooterUpperMenuButton[]>,
      default: () => [],
    },
    noteState: {
      type: Boolean,
    },
  },
  emits: ['close'],
  setup() {
    const activeButtons = ref<string[]>([])
    const toggleButton = (buttonName: string) => {
      activeButtons.value = activeButtons.value.includes(buttonName) ? activeButtons.value.filter(item => item !== buttonName) : [...activeButtons.value, buttonName]
    }

    return {
      activeButtons,
      toggleButton,
      tooltipDelayTime: delayOptions.long,
    }
  },
})
