
import { defineComponent, computed, toRefs } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import type { PriorityType, SizeProp } from '@/definitions/shared/types'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'TmPriority',
  components: {
    TmTooltip,
  },
  props: {
    type: {
      type: String as PropType<PriorityType>,
      required: true,
    },
    iconOnly: {
      type: Boolean,
    },
    size: {
      type: String as SizeProp<'small' | 'medium' | 'large'>,
      default: 'medium',
    },
    semiBold: {
      type: Boolean,
    },
    showTooltip: {
      type: Boolean,
    },
  },
  setup(props) {
    const { size, type } = toRefs(props)

    type SizeObj = {
      [key: string]: string;
    }

    const sizesIconMap: SizeObj = {
      small: '14px',
      medium: '16px',
      large: '18px',
    }

    const iconSize = computed<string>(() => {
      return sizesIconMap[size.value]
    })

    const typeMap: Record<number, {
      icon: string
      color: string
      title: string
    }> = {
      400: { icon: 'tmi-priority-urgent', color: 'red', title: 'Urgent' },
      300: { icon: 'tmi-priority-high', color: 'warning', title: 'High' },
      200: { icon: 'keyboard_arrow_up', color: 'primary', title: 'Normal' },
      100: { icon: 'keyboard_arrow_down', color: 'green', title: 'Low' },
    }

    const typeSelect = computed<{
      icon: string
      color: string
      title: string
    } | null>(() => (Number(type.value) in typeMap ? typeMap[Number(type.value)] : null))

    return {
      iconSize,
      typeMap,
      typeSelect,
    }
  },
})
