
import type { Ref } from 'vue'
import { computed, defineComponent, ref } from 'vue'
import MessageEditorUpperMenu from '@/components/shared/chat/messageEditor/MessageEditorUpperMenu.vue'
import type { MessageEditorFooterUpperMenuButton } from '@/components/shared/chat/messageEditor/MessageEditorUpperMenu.vue'

export default defineComponent({
  components: {
    MessageEditorUpperMenu,
  },
  emits: ['close'],
  setup() {
    const formatList = ref()
    const setFormatList = (format: string) => {
      formatList.value = formatList.value === format ? '' : format
    }

    const textDecorationButtons = ref<MessageEditorFooterUpperMenuButton[]>([
      {
        name: 'bold',
        icon: 'format_bold',
        tooltip: 'Bold',
      },
      {
        name: 'underline',
        icon: 'format_underlined',
        tooltip: 'Underline',
      },
      {
        name: 'italic',
        icon: 'format_italic',
        tooltip: 'Italic',
      },
      {
        name: 'strikethrough',
        icon: 'strikethrough_s',
        tooltip: 'Strikethrough',
        separatorAfter: true,
      },
      {
        name: 'code',
        icon: 'code',
        tooltip: 'Code',
      },
      {
        name: 'link',
        icon: 'link',
        tooltip: 'Link',
        separatorAfter: true,
      },
      {
        name: 'bulleted',
        icon: 'format_list_bulleted',
        tooltip: 'Bulleted list',
        isActive: computed(() => formatList.value === 'bulleted'),
        clickFunction: () => setFormatList('bulleted'),
      },
      {
        name: 'numbered',
        icon: 'format_list_numbered',
        tooltip: 'Ordered list',
        isActive: computed(() => formatList.value === 'numbered'),
        clickFunction: () => setFormatList('numbered'),
        separatorAfter: true,
      },
      {
        name: 'format-clear',
        icon: 'format_clear',
        tooltip: 'Clear formatting',
        clickFunction: () => {},
      },
    ]) as Ref<MessageEditorFooterUpperMenuButton[]>

    return {
      textDecorationButtons,
    }
  },
})
