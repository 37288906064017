
import type { PropType } from 'vue'
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from 'vue'
import { getStyle, normalizeToInterval, setAttrs, stopAndPrevent } from '@/services/utils'
import { keyCodes } from '@/definitions/_general/_data/keyCodes'
import isObject from 'lodash/isObject'

const { keyEnter, keyDown, keyUp, keyPageDown, keyPageUp } = keyCodes

type Item = {
  uid: string;
  title: string;
  disabled?: boolean;
  [key: string]: any;
}
export type { Item as InteractiveListItem }

export default defineComponent({
  name: 'TmInteractiveList',
  props: {
    overflow: {
      type: Boolean,
    },
    emitValue: {
      type: Boolean,
    },
    initialIndex: {
      type: Number,
      default: -1,
    },
    optionValue: {
      type: String,
      default: 'uid',
    },
    optionDisabled: {
      type: String,
      default: 'disabled',
    },
    items: {
      type: Array as PropType<Item[]>,
      required: true,
    },
  },
  emits: ['update:modelValue', 'onSelect'],
  setup(props, context) {
    const instanceUid = getCurrentInstance()?.uid
    const internalItems = computed(() => props.items.map((el: Item, i) => ({ ...el, listUid: `${instanceUid}_${i}` })))
    const optionIndex = ref(props.initialIndex)
    const optionsLength = computed(() => internalItems.value.length)
    const listboxEl = ref<HTMLElement>()
    const scrollbarWrapsEl = ref<HTMLElement | null>(null)
    const itemsELs = ref([])
    const itemNode = computed<HTMLElement>(() => itemsELs.value[optionIndex.value])
    const optionIndexInRange = computed(() => optionIndex.value > -1 && optionIndex.value < optionsLength.value)

    const listboxAttrs = computed(() => {
      const attrs = {
        id: `${instanceUid}_lb`,
        role: 'listbox',
        tabindex: '-1',
        'aria-activedescendant': '',
      }

      if (optionIndex.value >= 0) {
        attrs['aria-activedescendant'] = `${instanceUid}_${optionIndex.value}`
      }

      return attrs
    })
    const comboboxAttrs = computed(() => ({
      tabindex: '0',
      role: 'combobox',
      'aria-autocomplete': 'none',
      'aria-owns': `${instanceUid}_lb`,
      'aria-controls': `${instanceUid}_lb`,
    }))

    const getPropValueFn = (opt: Item, prop: string) => {
      if (isObject(opt) && prop in opt) {
        return opt[prop]
      }
      return opt
    }
    const getOptionValue = computed(() => (opt: Item) => getPropValueFn(opt, props.optionValue))
    const isOptionDisabled = computed(() => (opt: Item) => getPropValueFn(opt, props.optionDisabled))

    const scrollTarget = (el: HTMLElement) => {
      const scrollbarWrap = props.overflow ? listboxEl.value : scrollbarWrapsEl.value
      if (!el || !scrollbarWrap) {
        if (scrollbarWrap) scrollbarWrap.scrollTop = 0
        return
      }

      const { offsetTop: wrapOffsetTop, scrollTop, offsetHeight: wrapHeight } = scrollbarWrap
      const { offsetTop: elOffsetTop, offsetHeight: elHeight } = el
      const offsetTop = elOffsetTop - wrapOffsetTop
      const totalElHeight = offsetTop + elHeight
      if (totalElHeight > scrollTop + wrapHeight) {
        scrollbarWrap.scrollTop = totalElHeight - wrapHeight
      } else if (offsetTop < scrollTop) {
        scrollbarWrap.scrollTop = offsetTop
      }
    }

    const moveOptionSelection = (offset = 1) => {
      let index = optionIndex.value
      const isDisabledIndex = (index: number): boolean => index !== -1 &&
        index !== optionIndex.value &&
        isOptionDisabled.value(props.items[index] as Item) === true

      do {
        index = normalizeToInterval(
          index + offset,
          -1,
          optionsLength.value - 1
        )
      }
      while (isDisabledIndex(index))

      if (optionIndex.value !== index) {
        optionIndex.value = index
        scrollTarget(itemNode.value)
      }
    }

    const onUpdate = () => {
      if (props.emitValue) {
        context.emit('update:modelValue', getOptionValue.value(props.items[optionIndex.value] as Item))
      } else {
        context.emit('update:modelValue', props.items[optionIndex.value])
      }
      context.emit('onSelect')
    }

    const onKeydown = (e: KeyboardEvent) => {
      if (!e.target || ![keyEnter, keyDown, keyUp, keyPageDown, keyPageUp].includes(e.code)) {
        return
      }
      const target = e.target as HTMLElement
      setAttrs(target, comboboxAttrs.value)

      // pg up, pg down
      if (e.code === keyPageUp || e.code === keyPageDown) {
        stopAndPrevent(e)
        optionIndex.value = -1
        moveOptionSelection(e.code === keyPageUp ? 1 : -1)
      }

      // up, down
      if (e.code === keyUp || e.code === keyDown) {
        stopAndPrevent(e)
        moveOptionSelection(e.code === keyUp ? -1 : 1)
      }

      // enter
      if (e.code === keyEnter && optionIndexInRange.value) {
        const firstElementChild = itemNode.value.firstElementChild as HTMLElement
        firstElementChild.click()
        onUpdate()
      }
    }

    const onClick = onUpdate

    const onMousemove = (e: MouseEvent, i: number) => {
      const target = e.target as HTMLElement
      optionIndex.value = i
      setAttrs(target, comboboxAttrs.value)
    }

    onMounted(() => {
      const getScrollbarWraps = (el?: HTMLElement): HTMLElement | null => {
        const wrapper = el?.parentElement

        if (!wrapper) {
          return null
        }

        const overflow = [getStyle(wrapper, 'overflow'), getStyle(wrapper, 'overflow-y')]
        if (overflow.some((el) => !/scroll|auto/.test(el))) {
          return getScrollbarWraps(wrapper)
        }

        return wrapper
      }

      if (!props.overflow) scrollbarWrapsEl.value = getScrollbarWraps(listboxEl.value)
    })

    return {
      listboxEl,
      itemsELs,
      listboxAttrs,
      optionIndex,
      internalItems,
      onKeydown,
      onMousemove,
      onClick,
    }
  },
})
