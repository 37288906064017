import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8f8bf85e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "editor-buttons__divider"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor_button = _resolveComponent("editor-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.item.name === 'divider')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : (!_ctx.item.component)
        ? (_openBlock(), _createBlock(_component_editor_button, {
            key: 1,
            item: _ctx.item,
            disable: _ctx.disable,
            "note-state": _ctx.noteState,
            class: _normalizeClass({
        'is-active': _ctx.isActive,
        'tm-btn--show-label': _ctx.item.showLabel,
      })
          }, null, 8, ["item", "disable", "note-state", "class"]))
        : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.item.component), {
            key: 2,
            item: _ctx.item,
            disable: _ctx.disable
          }, {
            default: _withCtx(({ clickAction }) => [
              _createVNode(_component_editor_button, {
                item: _ctx.item,
                disable: _ctx.disable,
                "note-state": _ctx.noteState,
                class: _normalizeClass({
            'is-active': _ctx.isActive,
            'tm-btn--show-label': _ctx.item.showLabel,
          }),
                onClick: clickAction
              }, null, 8, ["item", "disable", "note-state", "class", "onClick"])
            ]),
            _: 1
          }, 8, ["item", "disable"]))
  ]))
}