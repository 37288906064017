
import { defineComponent } from 'vue'
import TmChip from '@/components/shared/TmChip.vue'
import Highlight from '@/components/shared/Highlight.vue'
import { capitalizeFirstLetter } from '@/services/string'

export default defineComponent({
  name: 'TmStatus',
  components: { TmChip, Highlight },
  props: {
    name: {
      type: String,
      default: '',
    },
    search: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      capitalizeFirstLetter,
    }
  },
})
