
import { defineComponent, ref } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import { randomIntFromInterval } from '@/services/utils'
import { getTableData } from '@/services/tableService'

const usersObj = getTableData('usersPreview')
const users = Object.keys(usersObj).map((id) => ({
  id,
  ...usersObj[id],
}))

export default defineComponent({
  components: {
    TmTooltip,
    TmBadge,
    TmAvatar,
  },
  setup() {
    const user = ref(users[randomIntFromInterval(0, users.length - 1)])

    return {
      user,
    }
  },
})
