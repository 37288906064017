
import type { PropType } from 'vue'
import { watch, computed, defineComponent, nextTick, ref } from 'vue'
import type { TooltipPosition } from '@/compositions/renderTooltip'
import { renderTooltip } from '@/compositions/renderTooltip'
import { delayOptions } from '@/definitions/_general/_data/general'

export default defineComponent({
  name: 'TmTooltip',
  props: {
    maxWidth: {
      type: String,
      default: '285px',
    },
    wrapperClass: {
      type: [String, Object],
    },
    delayed: {
      type: Boolean,
      default: true,
    },
    delayTime: {
      type: Number,
      default: delayOptions.default,
    },
    white: {
      type: Boolean,
    },
    hideArrow: {
      type: Boolean,
    },
    fullFilled: {
      type: Boolean,
    },
    class: {
      type: String,
    },
    unsetMaxHeight: {
      type: String,
    },
    target: {
      type: HTMLElement,
    },
    offset: {
      type: Array as PropType<number[]>,
    },
    offsetWithAngle: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String as PropType<TooltipPosition>,
      default: 'top',
    },
    popup: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const qTooltip = ref()
    const innerMenuRef = ref()
    const { getAngleAttrs, show, hide } = renderTooltip()
    const angleAttrs = ref(getAngleAttrs('tm-tooltip', props.position, props.offsetWithAngle))

    const attrs = computed(() => ({
      ...angleAttrs.value,
      ...context.attrs,
      offset: props.offset || angleAttrs.value.offset,
    }))

    const getAnchorEl = (el: HTMLElement) => {
      let anchorEl = el
      while (anchorEl.classList.contains('q-anchor--skip')) {
        anchorEl = anchorEl.parentElement as HTMLElement
      }
      return anchorEl
    }

    const beforeShow = async () => {
      const anchorEl = props.target || getAnchorEl(qTooltip.value.$el.parentElement)
      // for correct angle display need event between before-show and show, one nextTick not enough
      await nextTick()
      await nextTick()
      if (!innerMenuRef.value) { return }
      const menuEl = innerMenuRef.value.parentElement
      show(anchorEl, menuEl, attrs.value)
    }

    watch(() => props.position, () => {
      angleAttrs.value = getAngleAttrs('tm-tooltip', props.position, props.offsetWithAngle)
    })

    return {
      attrs,
      className: props.class,
      qTooltip,
      innerMenuRef,
      beforeShow,
      hide,
    }
  },
})
