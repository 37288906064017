
import { defineComponent, ref, nextTick, watch } from 'vue'
import { formatDate, strToDate } from '@/services/dateTimeService'
import TmDropdown from '@/components/shared/TmDropdown.vue'

export default defineComponent({
  components: { TmDropdown },
  props: {
    modelValue: {
      type: Date,
      default: null,
    },
    noUnset: {
      type: Boolean,
    },
    options: {
      type: Function,
      default: () => true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const menuIsVisible = ref(false)
    const dateValue = ref('')
    const calendarDateFormat = 'YYYY/MM/DD'

    const onDateUpdate = (value: string) => {
      const dateInDateFormat = value ? strToDate(value, calendarDateFormat) : null
      context.emit('update:modelValue', dateInDateFormat)
      closeMenu()
    }

    const setValue = (value: Date | null) => {
      dateValue.value = value ? formatDate(value, calendarDateFormat) : ''
    }

    const closeMenu = () => {
      menuIsVisible.value = false
    }
    const showMenu = () => {
      menuIsVisible.value = true
    }
    const updateMenuPosition = (qMenuRef: any) => {
      nextTick(qMenuRef.updatePosition)
    }

    watch(
      () => props.modelValue,
      (newValue) => setValue(newValue),
      { immediate: true }
    )

    return {
      menuIsVisible,
      dateValue,
      calendarDateFormat,
      onDateUpdate,
      updateMenuPosition,
      closeMenu,
      showMenu,
    }
  },
})
