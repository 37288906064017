import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e9cb20f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-user-preview__main" }
const _hoisted_2 = { class: "tm-user-preview__header" }
const _hoisted_3 = { class: "ml-4 overflow-hidden" }
const _hoisted_4 = { class: "headline-18 lh-24 neutral--text truncate" }
const _hoisted_5 = { class: "body-text-regular-14 lh-20 distinct--text" }
const _hoisted_6 = { class: "tm-user-preview__body" }
const _hoisted_7 = { class: "d-flex align-center mb-2" }
const _hoisted_8 = { class: "d-flex align-center mb-2" }
const _hoisted_9 = { class: "d-flex mb-2" }
const _hoisted_10 = { class: "d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!

  return (_openBlock(), _createBlock(_component_tm_tooltip, {
    class: "tm-user-preview",
    "hide-arrow": "",
    white: "",
    "max-width": "350px"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_tm_avatar, {
            url: _ctx.user.avatar,
            "placeholder-custom-size": "18px",
            "custom-size": "48px",
            status: "active",
            name: _ctx.user.fullName,
            color: _ctx.user.avatarColor
          }, null, 8, ["url", "name", "color"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.user.fullName), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.user.job), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_tm_icon, {
              name: "email",
              class: "mr-6px"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.user.email), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_tm_icon, {
              name: "phone",
              class: "mr-6px"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.user.phoneNumber), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_tm_icon, {
              name: "tmi-team",
              class: "mr-6px lh-20"
            }),
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.teams, (team, i) => {
                return (_openBlock(), _createBlock(_component_tm_badge, {
                  key: i,
                  class: "mr-2 mb-1"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(team), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_tm_icon, {
              name: "schedule",
              class: "mr-6px"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.user.lastActive), 1)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}