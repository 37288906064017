
import type { PropType } from 'vue'
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue'
import { observedScrollbarQuasarClassName } from '@/definitions/_general/_data/general'

export default defineComponent({
  name: 'TmScrollbar',
  props: {
    dark: {
      type: Boolean,
    },
    visible: {
      type: Boolean,
    },
    allowScrollX: {
      type: Boolean,
    },
    allowScrollY: {
      type: Boolean,
      default: true,
    },
    transparentScrollbar: {
      type: Boolean,
    },
    stable: {
      type: Boolean,
    },
    centering: {
      type: String as PropType<'both' | 'x' | 'y'>,
    },
    removeScrollbarWidth: {
      type: Boolean,
    },
    contentBottom: {
      type: Boolean,
    },
    hideScrollbar: {
      type: Boolean,
    },
  },
  emits: {
    'on-scroll': (position: { up: number, down: number, left: number, right: number }) => true,
    'on-x-reach-start': () => true,
    'on-x-reach-end': () => true,
    'on-y-reach-start': () => true,
    'on-y-reach-end': () => true,
    'on-x-reach-in': () => true,
  },
  setup(props, context) {
    let resizeObserver: ResizeObserver
    let scrollContentResizeObserver: ResizeObserver
    const scrollContent = ref()
    const scroll = ref()
    const visibleScrollbarY = ref(false)
    const visibleScrollbar = ref(false)
    const showOffset = ref(false)

    const showScrollbar = (() => {
      // fix scrollbar appearing on macOs when another window is focused
      let timerId: number
      return () => {
        visibleScrollbar.value = true
        clearTimeout(timerId)
        timerId = setTimeout(() => {
          visibleScrollbar.value = false
        }, 1500)
      }
    })()
    const onScrollContentResize = () => {
      const { offsetHeight: scrollHeight } = scroll.value
      const { offsetHeight: scrollContentHeight } = scrollContent.value

      visibleScrollbarY.value = scrollContentHeight > scrollHeight
    }

    const onScroll = () => {
      if (scroll.value) {
        const {
          offsetWidth, scrollWidth, scrollLeft,
          offsetHeight, scrollHeight, scrollTop,
        } = scroll.value
        context.emit('on-scroll', {
          up: scrollTop,
          down: Math.max(scrollHeight - offsetHeight - scrollTop - 1, 0),
          left: scrollLeft,
          right: Math.max(scrollWidth - offsetWidth - scrollLeft, 0),
        })
        showOffset.value = offsetHeight === scrollHeight
        if (scrollLeft === 0) {
          context.emit('on-x-reach-start')
        }
        if (scrollLeft > 0) {
          context.emit('on-x-reach-in')
        }
        if (offsetWidth + scrollLeft >= scrollWidth) {
          context.emit('on-x-reach-end')
        }
        if (scrollTop === 0) {
          context.emit('on-y-reach-start')
        }
        if (offsetHeight + scrollTop + 1 >= scrollHeight) {
          context.emit('on-y-reach-end')
        }
      }
    }

    const onScrollCallback = () => {
      showScrollbar()
      onScroll()
    }

    onMounted(() => {
      resizeObserver = new ResizeObserver(onScroll)
      scrollContentResizeObserver = new ResizeObserver(onScrollContentResize)
      onScroll()
      resizeObserver.observe(scroll.value)
      scrollContentResizeObserver.observe(scrollContent.value)
      scroll.value.addEventListener('scroll', onScrollCallback)

      if (props.centering === 'both') centeringByBoth()
      else if (props.centering === 'x') centeringByX()
      else if (props.centering === 'y') centeringByY()
    })

    onBeforeUnmount(() => {
      resizeObserver.disconnect()
      scrollContentResizeObserver.disconnect()
      scroll.value.removeEventListener('scroll', onScrollCallback)
    })

    // methods for using from parents
    const setScroll = (axes: { x?: number, y?: number }) => {
      if (axes.x) scroll.value.scrollLeft = axes.x
      if (axes.y) scroll.value.scrollTop = axes.y
    }
    const centeringByX = () => {
      const { scrollWidth, offsetWidth } = scroll.value
      if (scrollWidth > offsetWidth) setScroll({ x: (scrollWidth - offsetWidth) / 2 })
    }
    const centeringByY = () => {
      const { scrollHeight, offsetHeight } = scroll.value
      if (scrollHeight > offsetHeight) setScroll({ y: (scrollHeight - offsetHeight) / 2 })
    }
    const centeringByBoth = () => {
      centeringByX()
      centeringByY()
    }

    return {
      observedScrollbarQuasarClassName,
      showOffset,
      scroll,
      scrollContent,
      visibleScrollbar,
      visibleScrollbarY,
      // methods for using from parents
      setScroll,
      centeringByX,
      centeringByY,
    }
  },
})
