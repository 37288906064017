import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a4c92e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "editor-buttons__divider"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor_button_template = _resolveComponent("editor-button-template")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_auto_size_panel_item = _resolveComponent("tm-auto-size-panel-item")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_auto_size_panel = _resolveComponent("tm-auto-size-panel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editor-buttons", {
      'editor-buttons--note': _ctx.noteState,
    }])
  }, [
    _createVNode(_component_tm_auto_size_panel, { "show-tooltip": "" }, _createSlots({
      "dropdown-activator": _withCtx(() => [
        _createVNode(_component_tm_button, {
          "icon-only": "",
          flat: "",
          size: "medium",
          color: _ctx.noteState ? 'transparent-note' : 'transparent',
          disabled: _ctx.disable,
          class: "editor-buttons__btn"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_icon, {
              name: "more_horiz",
              size: "xLarge"
            })
          ]),
          _: 1
        }, 8, ["color", "disabled"])
      ]),
      "dropdown-after-permanent": _withCtx(() => [
        (_ctx.permanentButtons.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permanentButtons, (item, i) => {
          return (_openBlock(), _createBlock(_component_editor_button_template, {
            key: i,
            item: item,
            disable: _ctx.disable || item.disable,
            "is-active": _ctx.isBtnActive(item),
            "note-state": _ctx.noteState,
            onClick: ($event: any) => (_ctx.btnClick(item))
          }, null, 8, ["item", "disable", "is-active", "note-state", "onClick"]))
        }), 128))
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleButtons, (item, i) => {
          return (_openBlock(), _createBlock(_component_tm_auto_size_panel_item, {
            key: i,
            class: _normalizeClass(["d-flex align-center", item.permanent ? _ctx.tmAutoSizeIgnoreClass : ''])
          }, {
            "dropdown-item": _withCtx(() => [
              _createVNode(_component_tm_dropdown_item, {
                icon: item.icon,
                label: item.text,
                disable: _ctx.disable || item.disable,
                onClick: ($event: any) => (_ctx.btnClick(item))
              }, null, 8, ["icon", "label", "disable", "onClick"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_editor_button_template, {
                item: item,
                disable: _ctx.disable || item.disable,
                "is-active": _ctx.isBtnActive(item),
                "note-state": _ctx.noteState,
                onClick: ($event: any) => (_ctx.btnClick(item))
              }, null, 8, ["item", "disable", "is-active", "note-state", "onClick"])
            ]),
            _: 2
          }, 1032, ["class"]))
        }), 128))
      ]),
      _: 2
    }, [
      (_ctx.dropdownButtons.length)
        ? {
            name: "dropdown-default",
            fn: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdownButtons, (item, i) => {
                return (_openBlock(), _createBlock(_component_tm_dropdown_item, {
                  key: i,
                  icon: item.icon,
                  label: item.text,
                  disable: item.disable,
                  onClick: ($event: any) => (_ctx.btnClick(item))
                }, null, 8, ["icon", "label", "disable", "onClick"]))
              }), 128))
            ])
          }
        : undefined
    ]), 1024)
  ], 2))
}