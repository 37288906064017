import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64979357"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-dropdown-date-base-calendar__wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_date = _resolveComponent("q-date")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, {
    modelValue: _ctx.menuIsVisible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuIsVisible) = $event)),
    "no-focus": "",
    "no-refocus": "",
    "no-parent-event": "",
    "close-on-content-click": false
  }, {
    menu: _withCtx(({qMenu}) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_date, {
          "model-value": _ctx.dateValue,
          options: _ctx.options,
          class: "tm-dropdown-date-base-calendar",
          outlined: "",
          minimal: "",
          "first-day-of-week": "1",
          mask: _ctx.calendarDateFormat,
          "no-unset": _ctx.noUnset,
          "onUpdate:modelValue": _ctx.onDateUpdate,
          onClick: ($event: any) => (_ctx.updateMenuPosition(qMenu))
        }, null, 8, ["model-value", "options", "mask", "no-unset", "onUpdate:modelValue", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({
        showMenu: _ctx.showMenu,
        closeMenu: _ctx.closeMenu
      })), undefined, true)
    ]),
    _: 3
  }, 8, ["modelValue"]))
}