
import type { PropType } from 'vue'
import { computed, defineComponent, ref, watch } from 'vue'
import TmAutoSize from '@/components/shared/autoSize/TmAutoSize.vue'
import type { ChipSize, ChipColor } from '@/definitions/shared/types'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmChip from '@/components/shared/TmChip.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import { tmAutoSizeVisibleClass } from '@/definitions/shared/autoSize/data'

export default defineComponent({
  components: {
    TmAutoSize,
    TmBadge,
    TmChip,
    TmTooltip,
  },
  props: {
    itemsNames: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    visibleItems: {
      type: Number,
    },
    forceShowAll: {
      type: Boolean,
      default: false,
    },
    toggleBadgeSize: {
      type: String as PropType<ChipSize>,
      default: '',
    },
    toggleBadgeRounded: {
      type: Boolean,
    },
    light: {
      type: Boolean,
    },
    color: {
      type: String as PropType<ChipColor>,
      default: 'gray',
    },
    linesCount: {
      type: Number,
    },
    outline: {
      type: Boolean,
    },
    chip: {
      type: Boolean,
    },
    disableToggler: {
      type: Boolean,
    },
    hideTooltip: {
      type: Boolean,
    },
    moreText: {
      type: String,
    },
  },
  setup(props) {
    const tmAutoSizeRef = ref()
    const hiddenItemsCount = ref(0)
    const isExpanded = ref(false)
    const toggleView = () => {
      if (!props.disableToggler) {
        isExpanded.value = !isExpanded.value
        updateVisibleChildren()
      }
    }
    // cause one item is button show/hide
    const realHiddenItemsCount = computed(() => hiddenItemsCount.value - 1)
    // show and cut title for the first child
    const firstChildCut = computed(() => realHiddenItemsCount.value === props.itemsNames?.length)
    const countForButton = computed(() => firstChildCut.value ? (realHiddenItemsCount.value - 1) : realHiddenItemsCount.value)

    const toggleBadgeIsVisible = computed(() => realHiddenItemsCount.value > 0 || isExpanded.value)

    const updateVisibleChildren = () => {
      tmAutoSizeRef.value.updateVisibleChildren()
    }

    watch(() => props.itemsNames, updateVisibleChildren)

    return {
      tmAutoSizeRef,
      hiddenItemsCount,
      tmAutoSizeVisibleClass,
      isExpanded,
      toggleView,
      realHiddenItemsCount,
      firstChildCut,
      countForButton,
      toggleBadgeIsVisible,
      // public methods
      updateVisibleChildren,
    }
  },
})
