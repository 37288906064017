import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.popup ? 'q-popup-proxy' : 'q-tooltip'), _mergeProps({ ref: "qTooltip" }, _ctx.attrs, {
    class: ["tm-tooltip", [
      _ctx.wrapperClass,
      {
        'tm-tooltip--white': _ctx.white,
        'tm-tooltip--hide-arrow': _ctx.hideArrow,
        'tm-tooltip--full-filled': _ctx.fullFilled,
        'tm-tooltip--unset-max-height': _ctx.unsetMaxHeight,
      }
    ]],
    "max-width": _ctx.maxWidth,
    delay: _ctx.delayed ? _ctx.delayTime : 0,
    "transition-show": "fade",
    "transition-hide": "fade",
    onBeforeShow: _ctx.beforeShow,
    onHide: _ctx.hide
  }), {
    default: _withCtx(() => [
      _createElementVNode("div", {
        ref: "innerMenuRef",
        class: _normalizeClass(["tm-tooltip__main", _ctx.className])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ]),
    _: 3
  }, 16, ["class", "max-width", "delay", "onBeforeShow", "onHide"]))
}