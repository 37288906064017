
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  components: {
    TmTooltip,
  },
  props: {
    dropdownValue: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const parentRef = ref()
    const targetElement = ref<HTMLElement>()
    const tooltipValue = ref(false)

    const onMouseEnterEvent = () => {
      tooltipValue.value = !props.dropdownValue
    }

    const onMouseLeaveEvent = () => {
      tooltipValue.value = false
    }

    watch(() => props.dropdownValue, () => {
      tooltipValue.value = false
    })

    onMounted(() => {
      targetElement.value = parentRef.value.parentElement
      targetElement.value!.addEventListener('mouseenter', onMouseEnterEvent)
      targetElement.value!.addEventListener('mouseleave', onMouseLeaveEvent)
    })

    onBeforeUnmount(() => {
      targetElement.value!.removeEventListener('mouseenter', onMouseEnterEvent)
      targetElement.value!.removeEventListener('mouseleave', onMouseLeaveEvent)
    })

    return {
      parentRef,
      tooltipValue,
      targetElement,
    }
  },
})
