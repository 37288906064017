
import { defineComponent, computed } from 'vue'
import type { PropType } from 'vue'
import type { SizeProp, IconSize } from '@/definitions/shared/types'
import { randomIntFromInterval } from '@/services/utils'

type SkeletonTypes = 'text' | 'rect' | 'circle' | 'QBtn' | 'QBadge' | 'QChip' | 'QToolbar' | 'QCheckbox' | 'QRadio' | 'QToggle' | 'QSlider' | 'QRange' | 'QInput' | 'QAvatar'
type SkeletonAnimationTypes = 'wave' | 'pulse' | 'pulse-x' | 'pulse-y' | 'fade' | 'blink' | 'none'
type SkeletonSize = IconSize

export default defineComponent({
  props: {
    type: {
      type: String as PropType<SkeletonTypes>,
      default: 'rect',
    },
    animation: {
      type: String as PropType<SkeletonAnimationTypes>,
      default: 'wave',
    },
    dark: {
      type: Boolean,
    },
    tag: {
      type: String,
      default: 'div',
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    size: {
      type: String as SizeProp<SkeletonSize>,
      default: 'medium',
    },
    randomWidth: {
      type: Boolean,
    },
  },
  setup(props) {
    const sizesMap: Record<SkeletonSize, string> = {
      xSmall: '12px',
      small: '14px',
      medium: '16px',
      large: '18px',
      xLarge: '20px',
      xxLarge: '22px',
      xxxLarge: '24px',
    }

    const skeletonSize = computed<string | null>(() => {
      return sizesMap[props.size]
    })

    const widthValue = computed(() => {
      if (props.width) { return props.width }
      if (props.randomWidth) { return `${randomIntFromInterval(20, 80)}%` }

      return skeletonSize.value
    })

    const heightValue = computed(() => {
      if (props.height) { return props.height }

      return skeletonSize.value
    })

    return {
      skeletonSize,
      heightValue,
      widthValue,
    }
  },
})
