
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import type { FieldTextSize } from '@/components/shared/field/FieldText.vue'

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    size: {
      type: String as PropType<FieldTextSize>,
      default: '',
    },
    lightBorder: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    const field = ref()
    const focus = () => {
      field.value.focus()
    }
    const blur = () => {
      field.value.blur()
    }

    return {
      field,
      focus,
      blur,
    }
  },
})
